import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useParams } from "react-router-dom";
import api from "./api";

export default function Category() {
  const { slug } = useParams();
  const [metaData, setMetaData] = useState(null);
  const slugName = slug;
  const pageName = "Category";
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [products, setProducts] = useState([]);
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [filters, setFilters] = useState({
    amazon: false,
    flipkart: false,
    myntra: false,
    tataClIQ: false,
    croma: false,
    ajio: false,
    nykaa: false,
    nykaaMan: false,
    nykaaFashion: false,
  });
  const [page, setPage] = useState(1);
  const productsPerPage = 2;
  const [totalPages, setTotalPages] = useState(0);
  const topRef = useRef(null);

  useEffect(() => {
    const selectedFilters = Object.keys(filters)
      .filter((store) => filters[store])
      .join(",");

    const fetchCategoryProducts = async () => {
      try {
        const response = await api.post("", {
          method_name: "category_by_product_filter",
          category: slug,
          checkbox_filter: selectedFilters,
          page,
          per_page: productsPerPage,
        });

        const productsData = response.data.histro_Price || [];
        setCategoryProducts(productsData);
        setTotalPages(response.data.histro_Price[0]?.total_page || 0);

        const fetchMetaTags = async () => {
          try {
            const metaResponse = await api.post("", {
              method_name: "meta_keyword",
              page_name: pageName,
              slug_name: slugName,
            });

            const pageMeta = metaResponse.data.histro_Price[0];
            if (pageMeta) {
              setMetaData(pageMeta);
            } else {
              console.error("No meta data found for this page.");
            }
          } catch (error) {
            console.error("Error fetching meta data:", error);
          }
        };

        fetchMetaTags();
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchCategoryProducts();

    const fetchProducts = async () => {
      try {
        const response = await api.post("", {
          method_name: "top_products",
        });
        if (
          response.data &&
          response.data.histro_Price &&
          response.data.histro_Price.length > 0
        ) {
          setProducts(response.data.histro_Price);
        } else {
          throw new Error("No Products found");
        }
      } catch (err) {
        console.error("Error fetching products:", err);
        setError("Failed to load products.");
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [filters, slug, page]);

  const handleFilterChange = (e) => {
    const { name, checked } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: checked,
    }));
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    setPage((prevPage) => Math.max(1, prevPage - 1));
  };

  const isNextPageAvailable = () => {
    return page < totalPages;
  };

  const scrollToTop = () => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const renderMetaTags = () => {
    if (!metaData) return null;

    return (
      <>
        {metaData.description && (
          <meta name="description" content={metaData.description} />
        )}
        {metaData.keywords && (
          <meta name="keywords" content={metaData.keywords} />
        )}
        {metaData.image && (
          <meta name="robots" content="max-image-preview:large" />
        )}
        {metaData.url && <link rel="canonical" href={metaData.url} />}
        {metaData.type && <meta property="og:type" content={metaData.type} />}

        {metaData.title && (
          <meta property="og:title" content={metaData.title} />
        )}
        {metaData.description && (
          <meta property="og:description" content={metaData.description} />
        )}
        {metaData.url && <meta property="og:url" content={metaData.url} />}
        {metaData.image && (
          <meta property="og:image" content={metaData.image} />
        )}
        {metaData.image && (
          <meta property="og:image:secure_url" content={metaData.image} />
        )}
        {metaData.image && (
          <meta name="twitter:card" content="summary_large_image" />
        )}
        {metaData.title && (
          <meta name="twitter:title" content={metaData.title} />
        )}
        {metaData.description && (
          <meta name="twitter:description" content={metaData.description} />
        )}
        {metaData.image && (
          <meta name="twitter:image" content={metaData.image} />
        )}
      </>
    );
  };

  useEffect(() => {
    scrollToTop();
  }, [page]);

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>{metaData?.title || "Category - Histro Price"}</title>
          {renderMetaTags()}
        </Helmet>
        <section className="py-1">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3">
                <div className="processor">
                  <div className="heading d-flex justify-content-between align-items-center">
                    <p className="text-uppercase">
                      <b>Filters</b>
                    </p>
                  </div>
                  <div className="filter-group mt-2">
                    {Object.keys(filters).map((store) => (
                      <div className="form-check" key={store}>
                        <input
                          className="form-check-input filter_input"
                          type="checkbox"
                          name={store}
                          checked={filters[store]}
                          onChange={handleFilterChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`filter-${store}`}
                        >
                          {store.charAt(0).toUpperCase() + store.slice(1)}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="col-md-9 mt-2" ref={topRef}>
                <div className="three">
                  <h1>{slugName.toUpperCase()}</h1>
                </div>
                <div className="row">
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      role="tabpanel"
                      aria-labelledby="nav-all-tab"
                    >
                      <div className="product-grid row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
                        {categoryProducts.length > 0 ? (
                          categoryProducts.map((product) => (
                            <div className="col-md-4" key={product.id}>
                              <div className="product-item position-relative">
                                {product.sell_price && (
                                  <span className="badge bg-success position-absolute m-3">
                                    -
                                    {Math.round(
                                      ((parseFloat(product.price) -
                                        parseFloat(product.sell_price)) /
                                        parseFloat(product.price)) *
                                        100
                                    )}
                                    %
                                  </span>
                                )}
                                <figure>
                                  <a
                                    href={`/product/${product.slug}`}
                                    title={product.name}
                                  >
                                    <img
                                      src={product.image}
                                      className="tab-image"
                                      alt={product.name}
                                    />
                                  </a>
                                </figure>
                                <h3 className="product_name">{product.name}</h3>
                                <span className="rating">
                                  {[...Array(Math.floor(product.rate))].map(
                                    (_, index) => (
                                      <i
                                        key={index}
                                        className="fa fa-star text-primary"
                                        aria-hidden="true"
                                      ></i>
                                    )
                                  )}
                                  {product.rate % 1 !== 0 && (
                                    <i
                                      className="fa fa-star-half-alt text-primary"
                                      aria-hidden="true"
                                    ></i>
                                  )}
                                  {[...Array(5 - Math.ceil(product.rate))].map(
                                    (_, index) => (
                                      <i
                                        key={index + Math.floor(product.rate)}
                                        className="fa fa-star-o text-primary"
                                        aria-hidden="true"
                                      ></i>
                                    )
                                  )}
                                </span>
                                <span className="price">
                                  ₹{product.sell_price || product.price}
                                </span>
                                <div className="d-flex align-items-center justify-content-between">
                                  <span className="text-xs font-semibold uppercase text-gray-500">
                                    {product.store_name}
                                  </span>
                                  <time
                                    dateTime={product.updated_at}
                                    title={product.updated_at}
                                  >
                                    {product.updated_at}
                                  </time>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="product-grid productFound row row-cols-12 row-cols-sm-12 row-cols-md-12 row-cols-lg-12 row-cols-xl-12">
                            <div>
                              No products found for this category with selected
                              filters.
                            </div>
                          </div>
                        )}
                      </div>
                      {categoryProducts.length > 0 && totalPages > 1 && (
                        <div className="d-flex justify-content-between mt-3">
                          <button
                            className="btn btn-secondary"
                            onClick={handlePreviousPage}
                            disabled={page <= 1}
                          >
                            Previous
                          </button>
                          <button
                            className="btn btn-primary"
                            onClick={handleNextPage}
                            disabled={!isNextPageAvailable()}
                          >
                            Next
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="three">
                  <h2 className="top_design">Top Products</h2>
                </div>
                <div className="row">
                  <div className="tab-content">
                    <div
                      className="tab-pane fade show active"
                      role="tabpanel"
                      aria-labelledby="nav-all-tab"
                    >
                      <div className="product-grid row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
                        {loading ? (
                          <div>Loading products...</div>
                        ) : error ? (
                          <div>{error}</div>
                        ) : products.length === 0 ? (
                          <div>No products found</div>
                        ) : (
                          products.map((product) => (
                            <div className="col-md-4" key={product.id}>
                              <div className="product-item position-relative">
                                {" "}
                                {product.sell_price && (
                                  <span className="badge bg-success position-absolute m-3">
                                    -
                                    {Math.round(
                                      ((parseFloat(
                                        product.price.replace(/,/g, "")
                                      ) -
                                        parseFloat(
                                          product.sell_price.replace(/,/g, "")
                                        )) /
                                        parseFloat(
                                          product.price.replace(/,/g, "")
                                        )) *
                                        100
                                    )}
                                    %
                                  </span>
                                )}
                                <figure>
                                  <Link
                                    to={`/product/${product.slug}`}
                                    title={product.name}
                                  >
                                    <img
                                      src={product.image}
                                      className="tab-image"
                                      alt={product.name}
                                    />
                                  </Link>
                                </figure>
                                <h3 className="product_name">{product.name}</h3>
                                <span className="rating">
                                  {[...Array(Math.floor(product.rate))].map(
                                    (_, index) => (
                                      <i
                                        key={index}
                                        className="fa fa-star text-primary"
                                        aria-hidden="true"
                                      ></i>
                                    )
                                  )}
                                  {product.rate % 1 !== 0 && (
                                    <i
                                      className="fa fa-star-half-alt text-primary"
                                      aria-hidden="true"
                                    ></i>
                                  )}
                                  {[...Array(5 - Math.ceil(product.rate))].map(
                                    (_, index) => (
                                      <i
                                        key={index + Math.floor(product.rate)}
                                        className="fa fa-star-o text-primary"
                                        aria-hidden="true"
                                      ></i>
                                    )
                                  )}
                                </span>
                                <span className="price">
                                  ₹
                                  {product.sell_price
                                    ? product.sell_price
                                    : product.price}
                                </span>
                                <div className="d-flex align-items-center justify-content-between">
                                  <span className="text-xs font-semibold uppercase text-gray-500">
                                    {product.store_name}
                                  </span>
                                  {product.updated_at}
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </HelmetProvider>
  );
}
