import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import { Helmet, HelmetProvider } from "react-helmet-async";
import axios from "axios";
import api from "./api";

export default function Terms() {
  const [metaData, setMetaData] = useState(null);
  const pageName = "Terms";
  useEffect(() => {
    const fetchMetaTags = async () => {
      try {
        const response =  await api.post('', {
          method_name: "meta_keyword",
          page_name: pageName,
        });

        const pageMeta = response.data.histro_Price[0];

        if (pageMeta) {
          setMetaData(pageMeta);
        } else {
          console.error("No meta data found for this page.");
        }
      } catch (error) {
        console.error("Error fetching meta data:", error);
      }
    };

    fetchMetaTags();
  }, [pageName]);

  const renderMetaTags = () => {
    if (!metaData) return null;

    return (
      <>
        {metaData.description && (
          <meta name="description" content={metaData.description} />
        )}
        {metaData.keywords && (
          <meta name="keywords" content={metaData.keywords} />
        )}
        {metaData.image && (
          <meta name="robots" content="max-image-preview:large" />
        )}
        {metaData.url && <link rel="canonical" href={metaData.url} />}
        {metaData.type && <meta property="og:type" content={metaData.type} />}
        {metaData.title && (
          <meta property="og:title" content={metaData.title} />
        )}
        {metaData.description && (
          <meta property="og:description" content={metaData.description} />
        )}
        {metaData.url && <meta property="og:url" content={metaData.url} />}
        {metaData.image && (
          <meta property="og:image" content={metaData.image} />
        )}
        {metaData.image && (
          <meta property="og:image:secure_url" content={metaData.image} />
        )}
        {metaData.image && (
          <meta name="twitter:card" content="summary_large_image" />
        )}
        {metaData.title && (
          <meta name="twitter:title" content={metaData.title} />
        )}
        {metaData.description && (
          <meta name="twitter:description" content={metaData.description} />
        )}
        {metaData.image && (
          <meta name="twitter:image" content={metaData.image} />
        )}
      </>
    );
  };
  return (
    <div>
      <HelmetProvider>
        <div>
          <Helmet>
          <title>{metaData?.title || "Terms Conditions"}</title>
            {renderMetaTags()}
          </Helmet>
          <div className="container mt-1 mb-0">
            <div className="row d-flex justify-content-center">
              <div className="col-md-12">
              <div className="three"><h1>Terms Conditions</h1></div>
                <p>
                  Welcome to <b>Histro Price</b> These Terms and Conditions
                  ("Terms") govern your use of our website,
                  <a href="https://wavecloth.com/" target="_blank">
                    <b>www.wavecloth.com</b>
                  </a>
                  , and any services, content, or products offered through the
                  site (collectively, the "Services"). By accessing or using
                  Histro Price, you agree to comply with and be bound by these
                  Terms. If you do not agree to these Terms, please do not use
                  our site.
                </p>
                <h4>1. Acceptance of Terms</h4>
                <p>
                  By using or accessing the Histro Price website, you
                  acknowledge that you have read, understood, and agree to be
                  bound by these Terms and any additional terms or policies
                  posted on the website.
                </p>
                <h4>2. Changes to Terms</h4>
                <p>
                  We may update or change these Terms at any time without
                  notice. Any changes will be posted on this page with an
                  updated effective date. It is your responsibility to review
                  these Terms periodically. Your continued use of the website
                  after any changes are posted constitutes acceptance of those
                  changes.
                </p>
                <h4>3. Use of the Website</h4>
                <p>
                  You may use Histro Price for lawful purposes only and agree to
                  not engage in any conduct that may:
                </p>
                <ul>
                  <li>Violate any applicable law or regulation.</li>
                  <li>
                    Interfere with or disrupt the website’s functionality.
                  </li>
                  <li>
                    Engage in any activity that could harm our reputation or
                    that of our partners or affiliates.
                  </li>
                </ul>
                <h4>4. Account Registration</h4>
                <p>
                  To make purchases or access certain features of the website,
                  you may be required to create an account. By creating an
                  account, you agree to provide accurate, current, and complete
                  information and to maintain the security of your account
                  credentials. You are responsible for all activities that occur
                  under your account.
                </p>
                <h4>5. Products and Pricing</h4>
                <p>
                  Histro Price offers various products through the website. All
                  product descriptions, images, and prices are provided for
                  informational purposes. We reserve the right to modify or
                  discontinue any product at any time without notice.
                </p>
                <ul>
                  <li>
                    <b>Pricing:</b> All prices are displayed in [currency] and
                    may be subject to change. We make every effort to ensure the
                    accuracy of prices; however, errors may occur. In the event
                    of a pricing error, we reserve the right to cancel or modify
                    your order as necessary.
                  </li>
                  <li>
                    <b>Availability:</b> Product availability is not guaranteed
                    and may be subject to change. We will notify you if a
                    product becomes unavailable after you place an order.
                  </li>
                </ul>
                <h4>6. Order Process and Payment</h4>
                <p>
                  By placing an order on Histro Price, you are offering to
                  purchase the selected products in accordance with these Terms.
                  All orders are subject to acceptance and availability. After
                  placing an order, you will receive an email confirmation, but
                  this does not signify acceptance of your order.
                </p>
                <ul>
                  <li>
                    <b>Payment:</b> Payments are processed through secure
                    third-party payment processors. By providing payment
                    information, you authorize us to charge your chosen payment
                    method for the total amount of your order, including taxes
                    and shipping fees.
                  </li>
                </ul>
                <h4>7. Affiliate Program</h4>
                <p>
                  Histro Price participates in affiliate marketing programs. If
                  you click on an affiliate link and make a purchase through an
                  external website, we may earn a commission. The prices and
                  availability of products are not affected by this, and we make
                  no warranties or representations regarding the external sites
                  linked through the affiliate program.
                </p>
                <h4>8. Shipping and Delivery</h4>
                <p>
                  Shipping is provided by third-party carriers. Delivery times
                  and shipping costs may vary depending on your location and the
                  shipping method you choose.
                </p>
                <ul>
                  <li>
                    <b>Delivery Times:</b> Estimated delivery times are provided
                    at checkout but are not guaranteed. Histro Price is not
                    responsible for delays caused by the carrier or other
                    external factors.
                  </li>
                  <li>
                    <b></b>
                  </li>
                </ul>
                <h4>9. Returns and Refunds</h4>
                <p>
                  We want you to be satisfied with your purchase. If you are not
                  satisfied, you may be eligible for a return or exchange under
                  our return policy.
                </p>
                <ul>
                  <li>
                    <b>Return Policy:</b> Items must be returned within [insert
                    return period] days of delivery, in their original condition
                    and packaging, to be eligible for a refund or exchange. Some
                    items may be non-returnable (e.g., perishable goods, opened
                    products, etc.).
                  </li>
                  <li>
                    <b>Refunds:</b> If a return is approved, a refund will be
                    issued to the original payment method. Shipping charges are
                    non-refundable.
                  </li>
                </ul>
                <h4>10. Privacy and Data Protection</h4>
                <p>
                  Your privacy is important to us. Please refer to our Privacy
                  Policy for information on how we collect, use, and protect
                  your personal information. By using our site, you agree to the
                  collection and use of your data as described in the Privacy
                  Policy.
                </p>
                <h4>11. Intellectual Property</h4>
                <p>
                  All content on the website, including text, images, logos,
                  graphics, trademarks, and other materials, is owned by Histro
                  Price or its licensors and is protected by intellectual
                  property laws. You may not use, reproduce, or distribute any
                  content from this website without prior written permission.
                </p>
                <h4>12. Limitation of Liability</h4>
                <p>
                  To the fullest extent permitted by law, Histro Price shall not
                  be liable for any indirect, incidental, special, or
                  consequential damages arising from the use or inability to use
                  the website, products, or services.
                </p>
                <p>
                  In no event shall our liability exceed the amount you paid for
                  the product or service giving rise to the claim.
                </p>
                <h4>13. Indemnification</h4>
                <p>
                  You agree to indemnify, defend, and hold harmless Histro
                  Price, its affiliates, and its employees from any claims,
                  liabilities, damages, losses, or expenses (including
                  attorney’s fees) arising from your use of the website or
                  violation of these Terms.
                </p>
                <h4>14. Prohibited Activities</h4>
                <p>You may not:</p>
                <ul>
                  <li>
                    Use the website for any illegal or unauthorized purpose.
                  </li>
                  <li>
                    Post or transmit any content that is defamatory, obscene, or
                    infringes on the intellectual property rights of others.
                  </li>
                  <li>
                    Engage in any conduct that could damage, disable, or
                    overburden the website.
                  </li>
                </ul>
                <h4>15. Termination</h4>
                <p>
                  We reserve the right to suspend or terminate your access to
                  the website at our sole discretion, without notice, for any
                  reason, including but not limited to violation of these Terms.
                </p>
                <h4>16. Governing Law</h4>
                <p>
                  These Terms are governed by and construed in accordance with
                  the laws of [Insert your jurisdiction, e.g., the State of
                  [State], USA]. Any disputes arising from or related to these
                  Terms shall be subject to the exclusive jurisdiction of the
                  courts located in [State/City].
                </p>
                <h4>17. Severability</h4>
                <p>
                  If any provision of these Terms is found to be invalid or
                  unenforceable by a court of competent jurisdiction, the
                  remaining provisions will continue in full force and effect.
                </p>
                <h4>18. Contact Us</h4>
                <p>
                  If you have any questions about these Terms and Conditions,
                  please contact us at:
                </p>
                <br />
                <h3>
                  <b>Histro Price</b>
                </h3>
                <p>
                  Email: <b>info@wavecloth.com</b>
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </HelmetProvider>
    </div>
  );
}
