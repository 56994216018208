import React, { useState, useEffect, useRef } from "react";
import logo from "../images/logo.png";
import api from "./api";

export default function Footer() {
  const [currentYear, setCurrentYear] = useState(null);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const year = new Date().getFullYear();
    setCurrentYear(year);

    const fetchCategories = async () => {
      try {
        const response = await api.post("", {
          method_name: "footer_category",
        });
        if (
          response.data &&
          response.data.histro_Price &&
          response.data.histro_Price.length > 0
        ) {
          setCategories(response.data.histro_Price);
        } else {
          throw new Error("No categories found");
        }
      } catch (err) {
        console.error("Error fetching categories:", err);
        setError("Failed to load categories.");
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email) {
      setMessage(`Thank you for subscribing!`);
      setEmail("");
    } else {
      setMessage("Please enter a valid email address.");
    }
  };

  return (
    <div>
      <footer className="py-1">
        <div className="container-fluid text_center">
          <div className="row mt-4">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="footer-menu">
                <a href="/">
                  <img src={logo} alt="logo"  className="cust_logo_head_foot"/>
                </a>
              </div>
            </div>
            <br />
            <br />
            <br />
            <div className="col-md-3 col-sm-6">
              <div className="footer-menu">
                <h5 className="widget-title">Category</h5>
                <ul className="menu-list list-unstyled">
                  {loading ? (
                    <div>Loading categories...</div>
                  ) : error ? (
                    <div>{error}</div>
                  ) : categories.length === 0 ? (
                    <div>No categories found</div>
                  ) : (
                    categories.map((category) => (
                      <li className="menu-item" key={category.id}>
                        <a
                          href={`/category/${category.slug}`}
                          className="nav-link"
                        >
                          {category.name}
                        </a>
                      </li>
                    ))
                  )}
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="footer-menu">
                <h5 className="widget-title">Quick Links</h5>
                <ul className="menu-list list-unstyled">
                  <li className="menu-item">
                    <a href="/about" className="nav-link">
                      About us
                    </a>
                  </li>
                  <li className="menu-item">
                    <a href="/contact" className="nav-link">
                      Contact
                    </a>
                  </li>
                  <li className="menu-item">
                    <a href="/privacy" className="nav-link">
                      Privacy
                    </a>
                  </li>
                  <li className="menu-item">
                    <a href="/terms" className="nav-link">
                      Terms
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-6">
              <div className="footer-menu">
                <h5 className="widget-title">Subscribe Us</h5>
                <form className="d-flex mt-3 gap-0" onSubmit={handleSubmit}>
                  <input
                    className="form-control rounded-start rounded-0 bg-light"
                    type="email"
                    placeholder="Email Address"
                    aria-label="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button
                    className="btn btn-dark rounded-end rounded-0"
                    type="submit"
                  >
                    Subscribe
                  </button>
                </form>
                {message && <p className="mt-3 feedback">{message}</p>}
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div id="footer-bottom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 copyright">
              <p className="cust_copyright_p_tag">
                ©{currentYear} Histro Price. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
