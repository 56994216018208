import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import { Helmet, HelmetProvider } from "react-helmet-async";
import axios from "axios";
import api from "./api";
export default function Privacy() {
  const [metaData, setMetaData] = useState(null);
  const pageName = "Privacy";
  useEffect(() => {
    const fetchMetaTags = async () => {
      try {
        const response = await api.post("", {
          method_name: "meta_keyword",
          page_name: pageName,
        });

        const pageMeta = response.data.histro_Price[0];

        if (pageMeta) {
          setMetaData(pageMeta);
        } else {
          console.error("No meta data found for this page.");
        }
      } catch (error) {
        console.error("Error fetching meta data:", error);
      }
    };

    fetchMetaTags();
  }, [pageName]);

  const renderMetaTags = () => {
    if (!metaData) return null;

    return (
      <>
        {metaData.description && (
          <meta name="description" content={metaData.description} />
        )}
        {metaData.keywords && (
          <meta name="keywords" content={metaData.keywords} />
        )}
        {metaData.image && (
          <meta name="robots" content="max-image-preview:large" />
        )}
        {metaData.url && <link rel="canonical" href={metaData.url} />}
        {metaData.type && <meta property="og:type" content={metaData.type} />}
        {metaData.title && (
          <meta property="og:title" content={metaData.title} />
        )}
        {metaData.description && (
          <meta property="og:description" content={metaData.description} />
        )}
        {metaData.url && <meta property="og:url" content={metaData.url} />}
        {metaData.image && (
          <meta property="og:image" content={metaData.image} />
        )}
        {metaData.image && (
          <meta property="og:image:secure_url" content={metaData.image} />
        )}
        {metaData.image && (
          <meta name="twitter:card" content="summary_large_image" />
        )}
        {metaData.title && (
          <meta name="twitter:title" content={metaData.title} />
        )}
        {metaData.description && (
          <meta name="twitter:description" content={metaData.description} />
        )}
        {metaData.image && (
          <meta name="twitter:image" content={metaData.image} />
        )}
      </>
    );
  };
  return (
    <div>
      <HelmetProvider>
        <div>
          <Helmet>
          <title>{metaData?.title || "Privacy Policy"}</title>
            {renderMetaTags()}
          </Helmet>
          <div className="container mt-1 mb-0">
            <div className="row d-flex justify-content-center">
              <div className="col-md-12">
                <div className="three mb-4">
                  <h1>Privacy Policy</h1>
                </div>
                <h4>General</h4>
                <p>
                  At<b> Histro Price</b>, we value the privacy of our visitors
                  and customers. This Privacy Policy outlines the types of
                  personal information we collect, how we use it, and the steps
                  we take to protect it. By using our website, you agree to the
                  terms outlined below.
                </p>
                <h4>1. Information We Collect</h4>
                <p>
                  We collect information to improve your shopping experience and
                  ensure you have access to relevant products and services. This
                  information may include:
                </p>
                <ul>
                  <li>
                    <b>Personal Information: </b> When you register for an
                    account, make a purchase, or contact us, we may collect
                    personal information such as your name, email address, phone
                    number, and shipping address.
                  </li>
                  <li>
                    <b>Payment Information: </b> If you make a purchase, we
                    collect payment details (e.g., credit card number) through
                    secure payment processors.
                  </li>
                  <li>
                    <b>Usage Data: </b> We collect information about how you
                    interact with our website, including your IP address,
                    browser type, pages visited, and the date and time of your
                    visit.
                  </li>
                  <li>
                    <b>Affiliate Program Data: </b> If you click on affiliate
                    links or advertisements displayed on our site, we may
                    collect information related to your activity for affiliate
                    marketing purposes. This data helps track purchases made
                    through these links and compensates affiliate partners
                    accordingly.
                  </li>
                </ul>
                <h4>2. How We Use Your Information</h4>
                <p>We may use your information for the following purposes:</p>
                <ul>
                  <li>
                    <b>POrder Processing and Fulfillment: </b> To process your
                    orders, ship products, and communicate with you regarding
                    your purchase.
                  </li>
                  <li>
                    <b>Marketing and Promotions: </b> To send you updates on new
                    products, special offers, or promotions if you opt-in to our
                    newsletter or marketing communications.
                  </li>
                  <li>
                    <b>Affiliate Tracking: </b> To track purchases made through
                    affiliate links and compensate our affiliate partners.
                  </li>
                  <li>
                    <b>Improving User Experience: </b> To enhance the
                    performance and functionality of our website, making your
                    experience more enjoyable.
                  </li>
                  <li>
                    <b>Customer Support:</b> To respond to inquiries, provide
                    customer service, and resolve issues with your orders or
                    account.
                  </li>
                </ul>
                <h4>3. Affiliate Program and Third-Party Links</h4>
                <p>
                  <b>Histro Price</b> participates in affiliate marketing
                  programs. If you click on a link or advertisement from our
                  site that leads to a third-party website, we may earn a
                  commission based on your purchase or actions. These
                  third-party sites have their own privacy policies, and we
                  encourage you to review them before providing any personal
                  information.
                </p>
                <p>
                  We are not responsible for the content, privacy practices, or
                  policies of external sites.
                </p>
                <h4>4. Cookies and Tracking Technologies</h4>
                <p>
                  We use <b>cookies</b> and similar tracking technologies to
                  improve your browsing experience and track your interactions
                  with our website. Cookies are small text files placed on your
                  device that help us remember your preferences, and personalize
                  content and ads.
                </p>
                <p>
                  You can manage or disable cookies through your browser
                  settings, but doing so may affect your ability to use certain
                  features of our site.
                </p>
                <h4>5. Data Security</h4>
                <p>
                  We take the security of your personal information seriously
                  and implement appropriate physical, electronic, and
                  administrative safeguards to protect it. However, please note
                  that no method of transmission over the internet or method of
                  electronic storage is 100% secure. While we strive to protect
                  your information, we cannot guarantee its absolute security.
                </p>
                <h4>6. Sharing Your Information</h4>
                <p>
                  We may share your personal information in the following
                  circumstances:
                </p>
                <ul>
                  <li>
                    <b>Service Providers: </b> We may share your data with
                    trusted third-party vendors who assist in processing orders,
                    providing customer service, or sending promotional emails.
                  </li>
                  <li>
                    <b>Legal Requirements:</b> If required by law, we may
                    disclose your personal information in response to legal
                    processes, such as a subpoena or government request.
                  </li>
                  <li>
                    <b>Business Transfers: </b> In the event of a merger,
                    acquisition, or sale of Histro Price, your information may
                    be transferred as part of the transaction.
                  </li>
                </ul>
                <h4>7. Your Rights and Choices</h4>
                <p>You have the right to:</p>
                <ul>
                  <li>
                    <b>Access:</b> Request a copy of the personal information we
                    hold about you.
                  </li>
                  <li>
                    <b>Correction:</b> Correct any inaccurate or incomplete
                    personal information.
                  </li>
                  <li>
                    <b>Deletion:</b> Request that we delete your personal data,
                    subject to legal and contractual obligations.
                  </li>
                  <li>
                    <b>Opt-Out:</b> Opt-out of marketing communications by
                    following the unsubscribe instructions included in our
                    emails.
                  </li>
                </ul>
                <p>
                  To exercise any of these rights, please contact us at [Insert
                  Contact Information].
                </p>
                <h4>8. Children's Privacy</h4>
                <p>
                  Our website is not intended for children under the age of 13.
                  We do not knowingly collect personal information from
                  children. If we become aware that we have inadvertently
                  collected such information, we will take steps to delete it as
                  soon as possible.
                </p>
                <h4>9. Changes to This Privacy Policy</h4>
                <p>
                  We may update this Privacy Policy from time to time. Any
                  changes will be posted on this page with an updated effective
                  date. We encourage you to review this policy periodically for
                  any updates.
                </p>
                <h4>10. Contact Us</h4>
                <p>
                  If you have any questions about this Privacy Policy or how we
                  handle your personal information, please contact us at:
                </p>
                <br />
                <h3>
                  <b>Histro Price</b>
                </h3>
                <p>
                  Email: <b>info@wavecloth.com</b>
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </HelmetProvider>
    </div>
  );
}
