import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import about from "../images/about.png";
import { Helmet, HelmetProvider } from "react-helmet-async";
import api from "./api";
export default function About() 
{
  const [metaData, setMetaData] = useState(null);
  const pageName = "About";
  useEffect(() => {
    const fetchMetaTags = async () => {
      try {
        const response =  await api.post('', {
          method_name: "meta_keyword",
          page_name: pageName,
        });

        const pageMeta = response.data.histro_Price[0];

        if (pageMeta) {
          setMetaData(pageMeta);
        } else {
          console.error("No meta data found for this page.");
        }
      } catch (error) {
        console.error("Error fetching meta data:", error);
      }
    };

    fetchMetaTags();
  }, [pageName]);

  const renderMetaTags = () => {
    if (!metaData) return null;

    return (
      <>
        {metaData.description && (
          <meta name="description" content={metaData.description} />
        )}
        {metaData.keywords && (
          <meta name="keywords" content={metaData.keywords} />
        )}
        {metaData.image && <meta name="robots" content="max-image-preview:large" />}
        {metaData.url && <link rel="canonical" href={metaData.url} />}
        {metaData.type && <meta property="og:type" content={metaData.type} />}
        {metaData.title && (
          <meta property="og:title" content={metaData.title} />
        )}
        {metaData.description && (
          <meta property="og:description" content={metaData.description} />
        )}
        {metaData.url && <meta property="og:url" content={metaData.url} />}
        {metaData.image && (
          <meta property="og:image" content={metaData.image} />
        )}
        {metaData.image && (
          <meta property="og:image:secure_url" content={metaData.image} />
        )}
        {metaData.image && (
          <meta name="twitter:card" content="summary_large_image" />
        )}
        {metaData.title && (
          <meta name="twitter:title" content={metaData.title} />
        )}
        {metaData.description && (
          <meta name="twitter:description" content={metaData.description} />
        )}
        {metaData.image && (
          <meta name="twitter:image" content={metaData.image} />
        )}
      </>
    );
  };
  return (
    <div>
      <HelmetProvider>
        <div>
          <Helmet>
          <title>{metaData?.title || "About Us"}</title>
            {renderMetaTags()}
          </Helmet>
          <section className="py-0 my-0">
            <div className="container-fluid">
              <div className="bg-warning py-3 rounded-5">
                <div className="container">
                  <div className="row">
                    <div className="col-md-4">
                      <img
                        src={about}
                        alt="phone"
                        className="image-float img-fluid"
                      />
                    </div>
                    <div className="col-md-8">
                      <div className="three mb-4">
                        <h1>About Us</h1>
                      </div>
                      <div className="d-flex gap-2 flex-wrap">
                        <p>Welcome to Histro Price</p>
                        <p>
                          At Histro Price, we are dedicated to making
                          your shopping experience as seamless and informative
                          as possible. Our platform leverages the power of
                          eCommerce and affiliate marketing to bring you the
                          best food products, right at your fingertips.
                        </p>
                        <p>
                          We showcase a wide variety of food items, from
                          everyday essentials to specialty ingredients, with
                          detailed product information, including images, names,
                          descriptions, and more. By partnering with trusted
                          affiliates, we ensure that every product featured on
                          our site is available for purchase with just a click.
                        </p>
                      </div>
                      <h3>How We Work</h3>
                      <p>
                        Through our carefully curated affiliate program, we
                        collect and display rich product data so you can easily
                        find exactly what you're looking for. Whether you're
                        browsing for fresh produce, pantry staples, or gourmet
                        treats, we provide all the information you need to make
                        informed decisions. With each product, you’ll find
                        high-quality images, thorough descriptions, and
                        important details that help you shop with confidence.
                      </p>
                      <h3>Our Mission</h3>
                      <p>
                        Our mission is simple: to make online grocery shopping
                        easier and more efficient for everyone. By connecting
                        you with reputable brands through affiliate
                        partnerships, we bring a vast selection of products into
                        one convenient space, ensuring you get the best options
                        available.
                      </p>
                      <h3>Why Choose Us?</h3>
                      <ul>
                        <li>
                            <b>Wide Selection :</b> From food basics to rare ingredients, our selection has something for every taste and need.
                        </li>
                        <li>
                            <b>Trusted Affiliates :</b> We work with reliable brands and retailers to ensure high-quality products.
                        </li>
                        <li>
                            <b>Detailed Information :</b> Each item is presented with clear images, comprehensive descriptions, and accurate data to help you make the best choices.
                        </li>
                        <li>
                            <b>Convenience :</b> Shop from the comfort of your home and get the food products you love delivered directly to your door.
                        </li>
                      </ul>
                      <p>Thank you for choosing <b>Histro Price</b>. We’re excited to be part of your shopping journey!</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <br />
        <Footer />
      </HelmetProvider>
    </div>
  );
}
