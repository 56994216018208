import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { Helmet, HelmetProvider } from "react-helmet-async";
import api from "./api";
export default function Filter() {
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();
  const [metaData, setMetaData] = useState(null);
  const pageName = "Filter";
  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const query = params.get("query") || "";
    setSearchQuery(query);

    const fetchData = async () => {
      try {
        const response = await api.post("", {
          method_name: "filter",
          filter: query,
          page: currentPage,
        });

        const data = response.data;

        if (data && data.histro_Price) {
          setFilteredProducts(data.histro_Price);
          setTotalPages(data.histro_Price[0]?.total_page || 0);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    const fetchMetaTags = async () => {
      try {
        const response = await api.post("", {
          method_name: "meta_keyword",
          page_name: pageName,
        });

        const pageMeta = response.data.histro_Price[0];

        if (pageMeta) {
          setMetaData(pageMeta);
        } else {
          console.error("No meta data found for this page.");
        }
      } catch (error) {
        console.error("Error fetching meta data:", error);
      }
    };

    fetchMetaTags();
  }, [location.search, currentPage, pageName]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const renderMetaTags = () => {
    if (!metaData) return null;

    return (
      <>
        {metaData.description && (
          <meta name="description" content={metaData.description} />
        )}
        {metaData.keywords && (
          <meta name="keywords" content={metaData.keywords} />
        )}
        {metaData.image && (
          <meta name="robots" content="max-image-preview:large" />
        )}
        {metaData.url && <link rel="canonical" href={metaData.url} />}
        {metaData.type && <meta property="og:type" content={metaData.type} />}
        {metaData.title && (
          <meta property="og:title" content={metaData.title} />
        )}
        {metaData.description && (
          <meta property="og:description" content={metaData.description} />
        )}
        {metaData.url && <meta property="og:url" content={metaData.url} />}
        {metaData.image && (
          <meta property="og:image" content={metaData.image} />
        )}
        {metaData.image && (
          <meta property="og:image:secure_url" content={metaData.image} />
        )}
        {metaData.image && (
          <meta name="twitter:card" content="summary_large_image" />
        )}
        {metaData.title && (
          <meta name="twitter:title" content={metaData.title} />
        )}
        {metaData.description && (
          <meta name="twitter:description" content={metaData.description} />
        )}
        {metaData.image && (
          <meta name="twitter:image" content={metaData.image} />
        )}
      </>
    );
  };
  return (
    <div>
      <HelmetProvider>
        <div>
          <Helmet>
          <title>{metaData?.title || "Filter"}</title>
            {renderMetaTags()}
          </Helmet>
          <section className="py-1">
            <div className="container-fluid mt-4">
              <h1>Search Results for: {searchQuery}</h1>
              <div className="row">
                {filteredProducts.length > 0 ? (
                  filteredProducts.map((product) => (
                    <div className="col-md-3" key={product.id}>
                      <div className="product-item position-relative">
                        {product.sell_price && (
                          <span className="badge bg-success position-absolute m-3">
                            -{" "}
                            {Math.round(
                              ((parseFloat(product.price.replace(/,/g, "")) -
                                parseFloat(
                                  product.sell_price.replace(/,/g, "")
                                )) /
                                parseFloat(product.price.replace(/,/g, ""))) *
                                100
                            )}
                            %
                          </span>
                        )}
                        <figure>
                          <Link
                            to={`/product/${product.slug}`}
                            title={product.name}
                          >
                            <img
                              src={product.image}
                              className="tab-image"
                              alt={product.name}
                            />
                          </Link>
                        </figure>
                        <h3 className="product_name">{product.name}</h3>
                        <span className="rating">
                          {[...Array(Math.floor(product.rate))].map(
                            (_, index) => (
                              <i
                                key={index}
                                className="fa fa-star text-primary"
                                aria-hidden="true"
                              ></i>
                            )
                          )}
                          {product.rate % 1 !== 0 && (
                            <i
                              className="fa fa-star-half-alt text-primary"
                              aria-hidden="true"
                            ></i>
                          )}
                          {[...Array(5 - Math.ceil(product.rate))].map(
                            (_, index) => (
                              <i
                                key={index + Math.floor(product.rate)}
                                className="fa fa-star-o text-primary"
                                aria-hidden="true"
                              ></i>
                            )
                          )}
                        </span>
                        <span className="price">
                          ₹{product.sell_price || product.price}
                        </span>
                        <div className="d-flex align-items-center justify-content-between">
                          <span className="text-xs font-semibold uppercase text-gray-500">
                            {product.store_name}
                          </span>
                          {product.updated_at}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No items match your search criteria➡️ "{searchQuery}"</p>
                )}
              </div>

              <div className="d-flex justify-content-between mt-3">
                <button
                  className="btn btn-secondary"
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>

                <div className="pagination-info">
                  <span className="text-muted">
                    Page {currentPage} of {totalPages}
                  </span>
                </div>

                <button
                  className="btn btn-primary"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </HelmetProvider>
    </div>
  );
}
