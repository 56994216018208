import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { Helmet, HelmetProvider } from "react-helmet-async";
import thumb1 from "../images/banner1.png";
import thumb2 from "../images/banner2.png";
import thumb3 from "../images/banner3.png";
import "swiper/swiper-bundle.css";
import api from "./api";

export default function Main() {
  const [metaData, setMetaData] = useState(null);
  const pageName = "Main";

  const [activeTab, setActiveTab] = useState("General");
  const [products, setProducts] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response =  await api.post('', {
          method_name: "home",
        });
        if (
          response.data &&
          response.data.histro_Price &&
          response.data.histro_Price.length > 0
        ) {
          setProducts(response.data.histro_Price);
        } else {
          throw new Error("No Products found");
        }
      } catch (err) {
        console.error("Error fetching products:", err);
        setError("Failed to load products.");
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();

    const fetchMetaTags = async () => {
      try {
        const response =  await api.post('', {
          method_name: "meta_keyword",
          page_name: pageName,
        });

        const pageMeta = response.data.histro_Price[0];

        if (pageMeta) {
          setMetaData(pageMeta);
        } else {
          console.error("No meta data found for this page.");
        }
      } catch (error) {
        console.error("Error fetching meta data:", error);
      }
    };

    fetchMetaTags();
  }, [pageName]);

  const renderMetaTags = () => {
    if (!metaData) return null;

    return (
      <>
        {metaData.description && (
          <meta name="description" content={metaData.description} />
        )}
        {metaData.keywords && (
          <meta name="keywords" content={metaData.keywords} />
        )}
        {metaData.image && (
          <meta name="robots" content="max-image-preview:large" />
        )}
        {metaData.url && <link rel="canonical" href={metaData.url} />}
        {metaData.type && <meta property="og:type" content={metaData.type} />}
        {metaData.title && (
          <meta property="og:title" content={metaData.title} />
        )}
        {metaData.description && (
          <meta property="og:description" content={metaData.description} />
        )}
        {metaData.url && <meta property="og:url" content={metaData.url} />}
        {metaData.image && (
          <meta property="og:image" content={metaData.image} />
        )}
        {metaData.image && (
          <meta property="og:image:secure_url" content={metaData.image} />
        )}
        {metaData.image && (
          <meta name="twitter:card" content="summary_large_image" />
        )}
        {metaData.title && (
          <meta name="twitter:title" content={metaData.title} />
        )}
        {metaData.description && (
          <meta name="twitter:description" content={metaData.description} />
        )}
        {metaData.image && (
          <meta name="twitter:image" content={metaData.image} />
        )}
      </>
    );
  };

  const renderContent = () => {
    switch (activeTab) {
      case "General":
        return (
          <div className="row">
            <div className="col-md-6">
              <h4>
                <b>What is a Histro Price?</b>
              </h4>
              <p>
                <b>Histro Price</b> is a free website and an app that represents
                a graphical interface to view historical price charts of a
                particular product for various e-commerce stores. It also helps
                you to choose the best time to buy a product at the best price.
              </p>
            </div>
            <div className="col-md-6">
              <h4>
                <b>What is a Price Tracker?</b>
              </h4>
              <p>
                <b>Price Tracker</b> is a term used to represent a tool that
                automatically tracks the price of a product for you, so you
                don't have to check the price manually several times a day.
              </p>
            </div>
            <div className="col-md-6">
              <h4>
                <b>What is a Price Drop Alert?</b>
              </h4>
              <p>
                A <b>price drop alert</b> is a notification that you will
                receive on your device or e-mail once the price of a product
                you're tracking hits your set target price. Histro Price
                monitors the price of a product and notifies you as well.
              </p>
            </div>
            <div className="col-md-6">
              <h4>
                <b>How do I set a Price Alert?</b>
              </h4>
              <p>
                Open the product in <b>Histro Price app</b> that you're looking
                to purchase. In the right bottom corner, you can tap the bell
                icon and then tap "set price alert" button. You can manually
                enter your target price and hit save button.
              </p>
            </div>
          </div>
        );
      case "Histro Price":
        return (
          <div className="row">
            <div className="col-md-6">
              <h4>
                <b>What is the use of Histro Price?</b>
              </h4>
              <p>
                E-commerce stores change price of their products very
                frequently. It is recommended to see <b>Histro Price</b> charts
                of products you are looking to buy. Price charts can help you
                decide whether to buy the product or not. You can analyze price
                data of product from few months to even years.
              </p>
            </div>
            <div className="col-md-6">
              <h4>
                <b>What are the benefits of Price Alerts?</b>
              </h4>
              <p>
                <b>Price alerts</b> are a great way to stay updated with the
                price of a product. Instead of manually checking price of
                products now and then, with price alert feature you can get
                notified instantly when the price of a product drops. You can
                also get notified when your product comes back in stock.
              </p>
            </div>
            <div className="col-md-6">
              <h4>
                <b>How accurate are price charts of Histro Price?</b>
              </h4>
              <p>
                We are third party tool which gathers price information from
                different stores. There can be some inaccuracies in price
                charts, but we are always working on improving the accuracy of
                price charts. We believe our price charts are 99% accurate.
              </p>
            </div>
            <div className="col-md-6">
              <h4>
                <b>What stores are supported by Histro Price?</b>
              </h4>
              <p>
                <b>Histro Price</b> supports major e-commerce stores from India
                (i.e. Amazon, Flipkart, Myntra, etc). Full list can be found on
                supported stores page. We are working on adding more stores and
                support them soon.
              </p>
            </div>
          </div>
        );
      case "How-to?":
        return (
          <div className="row">
            <div className="col-md-6">
              <h4>
                <b>How to analyze price charts?</b>
              </h4>
              <p>
                To analyze a price chart/graph, you have to keep current price
                in mind. Hover over the <b>Histro Price</b> chart to check price
                for specific dates. Each day can also have multiple prices. You
                can also switch view to 1 month, 3 months, 6 months and 1 year.
              </p>
            </div>
            <div className="col-md-6">
              <h4>
                <b>How to calculate target price from price chart?</b>
              </h4>
              <p>
                Make sure to check current price of a product. Check what are
                the chances of drop from "Is it good time to buy a product?"
                box. Check the lowest price from price chart. Check how many
                instances are there for lowest price. Based on these
                calculations you can find a target price for setting price
                alert.
              </p>
            </div>
            <div className="col-md-6">
              <h4>
                <b>How to zoom in to price chart?</b>
              </h4>
              <p>
                We have 4 default zoom levels (1M, 3M, 6M and 1Y) for easier
                zooming into price chart. You can also use custom zoom support
                by clicking (+) and (-) buttons on the right side of price
                chart.
              </p>
            </div>
            <div className="col-md-6">
              <h4>
                <b>How to embed price chart on my website?</b>
              </h4>
              <p>
                Open the product page on our site. You will notice grey colored
                button named with (). Tap on the button, copy the whole snippet.
                You can paste it on your website where you wish to embed our
                price chart.
              </p>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <HelmetProvider>
      <div>
        <Helmet>
        <title>{metaData?.title || "Histro Price"}</title>
          {renderMetaTags()}
        </Helmet>
        <section className="py-3 back1">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="banner-blocks">
                  <div className="banner-ad   block-1">
                    <div className="content-wrapper col-md-12">
                      <a href="https://wavecloth.com/category/christmas-special"><img src={thumb1}  alt="thumbnail" /></a>
                    </div>
                  </div>
                  <div className="banner-ad bg-success-subtle block-2">
                    <a href="https://wavecloth.com/category/western-wear"><img src={thumb2} alt="thumbnail" /></a>
                  </div>

                  <div className="banner-ad bg-danger block-3">
                  <a href="https://wavecloth.com/category/christmas-special"><img src={thumb3}  alt="thumbnail" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="py-4">
          <div className="container-fluid">
            <div className="row">
              <div className="tab-content" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="nav-all"
                  role="tabpanel"
                  aria-labelledby="nav-all-tab"
                >
                  <div className="product-grid row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
                    <div className="three">
                      <h1>Histro Price</h1>
                    </div>
                    {loading ? (
                      <div>Loading products...</div>
                    ) : error ? (
                      <div>{error}</div>
                    ) : products.length === 0 ? (
                      <div>No products found</div>
                    ) : (
                      products.map((product) => (
                        <div className="col-md-3" key={product.id}>
                          <div className="product-item position-relative">
                            {" "}
                            {product.sell_price && (
                              <span className="badge bg-success position-absolute m-3">
                                -
                                {Math.round(
                                  ((parseFloat(
                                    product.price.replace(/,/g, "")
                                  ) -
                                    parseFloat(
                                      product.sell_price.replace(/,/g, "")
                                    )) /
                                    parseFloat(
                                      product.price.replace(/,/g, "")
                                    )) *
                                    100
                                )}
                                %
                              </span>
                            )}
                            <figure>
                              <Link
                                to={`/product/${product.slug}`}
                                title={product.name}
                              >
                                <img
                                  src={product.image}
                                  className="tab-image"
                                  alt={product.name}
                                />
                              </Link>
                            </figure>
                            <h3 className="product_name">{product.name}</h3>
                            <span className="rating">
                              {[...Array(Math.floor(product.rate))].map(
                                (_, index) => (
                                  <i
                                    key={index}
                                    className="fa fa-star text-primary"
                                    aria-hidden="true"
                                  ></i>
                                )
                              )}
                              {product.rate % 1 !== 0 && (
                                <i
                                  className="fa fa-star-half-alt text-primary"
                                  aria-hidden="true"
                                ></i>
                              )}
                              {[...Array(5 - Math.ceil(product.rate))].map(
                                (_, index) => (
                                  <i
                                    key={index + Math.floor(product.rate)}
                                    className="fa fa-star-o text-primary"
                                    aria-hidden="true"
                                  ></i>
                                )
                              )}
                            </span>
                            <span className="price">
                              ₹
                              {product.sell_price
                                ? product.sell_price
                                : product.price}
                            </span>
                            <div className="d-flex align-items-center justify-content-between">
                              <span className="text-xs font-semibold uppercase text-gray-500">
                                {product.store_name}
                              </span>
                              {product.updated_at}
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="py-2 section_back_color">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="flex flex-wrap justify-center">
                  <div className="relative flex-shrink-0 px-3 mt-0 w-full max-w-full text-center md:w-2/3 md:flex-none lg:w-1/2 lg:flex-none">
                    <h3 className="mt-4 mb-2 font-sans text-3xl font-bold xl:text-3xl">
                      How to get notified when price drops?
                    </h3>
                    <p className="text-gray-500 dark:text-gray-400">
                      It's simple. Download Histro Price app and follow these
                      three steps.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="py-3 section_back_color">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="card text-center">
                  <div className="card-body">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="50"
                      height="50"
                      viewBox="0 0 24 24"
                      className="svg_icon"
                    >
                      <path
                        fill="currentColor"
                        d="M21.5 15a3 3 0 0 0-1.9-2.78l1.87-7a1 1 0 0 0-.18-.87A1 1 0 0 0 20.5 4H6.8l-.33-1.26A1 1 0 0 0 5.5 2h-2v2h1.23l2.48 9.26a1 1 0 0 0 1 .74H18.5a1 1 0 0 1 0 2h-13a1 1 0 0 0 0 2h1.18a3 3 0 1 0 5.64 0h2.36a3 3 0 1 0 5.82 1a2.94 2.94 0 0 0-.4-1.47A3 3 0 0 0 21.5 15Zm-3.91-3H9L7.34 6H19.2ZM9.5 20a1 1 0 1 1 1-1a1 1 0 0 1-1 1Zm8 0a1 1 0 1 1 1-1a1 1 0 0 1-1 1Z"
                      />
                    </svg>
                    <h5>Open product page</h5>
                    <p className="card-text">
                      Find your product via search or url and open it in Price
                      History app.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card text-center">
                  <div className="card-body">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="50"
                      height="50"
                      viewBox="0 0 24 24"
                      className="svg_icon"
                    >
                      <path
                        fill="currentColor"
                        d="M19.63 3.65a1 1 0 0 0-.84-.2a8 8 0 0 1-6.22-1.27a1 1 0 0 0-1.14 0a8 8 0 0 1-6.22 1.27a1 1 0 0 0-.84.2a1 1 0 0 0-.37.78v7.45a9 9 0 0 0 3.77 7.33l3.65 2.6a1 1 0 0 0 1.16 0l3.65-2.6A9 9 0 0 0 20 11.88V4.43a1 1 0 0 0-.37-.78ZM18 11.88a7 7 0 0 1-2.93 5.7L12 19.77l-3.07-2.19A7 7 0 0 1 6 11.88v-6.3a10 10 0 0 0 6-1.39a10 10 0 0 0 6 1.39Zm-4.46-2.29l-2.69 2.7l-.89-.9a1 1 0 0 0-1.42 1.42l1.6 1.6a1 1 0 0 0 1.42 0L15 11a1 1 0 0 0-1.42-1.42Z"
                      />
                    </svg>
                    <h5>Set alerts</h5>
                    <p className="card-text">
                      Tap set price alert or set stock alert button, enter your
                      target price.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card text-center">
                  <div className="card-body">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="50"
                      height="50"
                      viewBox="0 0 24 24"
                      className="svg_icon"
                    >
                      <path
                        fill="currentColor"
                        d="M22 5H2a1 1 0 0 0-1 1v4a3 3 0 0 0 2 2.82V22a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-9.18A3 3 0 0 0 23 10V6a1 1 0 0 0-1-1Zm-7 2h2v3a1 1 0 0 1-2 0Zm-4 0h2v3a1 1 0 0 1-2 0ZM7 7h2v3a1 1 0 0 1-2 0Zm-3 4a1 1 0 0 1-1-1V7h2v3a1 1 0 0 1-1 1Zm10 10h-4v-2a2 2 0 0 1 4 0Zm5 0h-3v-2a4 4 0 0 0-8 0v2H5v-8.18a3.17 3.17 0 0 0 1-.6a3 3 0 0 0 4 0a3 3 0 0 0 4 0a3 3 0 0 0 4 0a3.17 3.17 0 0 0 1 .6Zm2-11a1 1 0 0 1-2 0V7h2ZM4.3 3H20a1 1 0 0 0 0-2H4.3a1 1 0 0 0 0 2Z"
                      />
                    </svg>
                    <h5>Get notified</h5>
                    <p className="card-text">
                      Based on stock, price, you'll be notified when the product
                      price drops.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="py-3">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>Frequently Asked Questions</h2>
                <p>Some of the most asked questions by our users</p>
                <div className="tabs">
                  <button
                    className={`tab-button ${
                      activeTab === "General" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("General")}
                  >
                    General
                  </button>
                  <button
                    className={`tab-button ${
                      activeTab === "Histro Price" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("Histro Price")}
                  >
                    Histro Price
                  </button>
                  <button
                    className={`tab-button ${
                      activeTab === "How-to?" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("How-to?")}
                  >
                    How-to?
                  </button>
                </div>
                <div className="tab-content">{renderContent()}</div>
              </div>
            </div>
          </div>
        </section>
        <section className="py-2 section_back_color_2">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mt-3">
                <h3>Supported Stores</h3>
                <p>Some of the most asked questions by our users</p>

                <h4>Histro Price For Amazon</h4>
                <p>
                  Histro Price provides Histro Price and price tracker for
                  Amazon. With this tool, you check the Histro Price of all
                  Amazon products and can also set price alerts. Amazon keeps
                  changing its inventory pricing and it is the best tool to get
                  full-price insights of Amazon products.
                </p>
                <h4>Histro Price For Flipkart</h4>
                <p>
                  Flipkart is the top e-commerce store in India.We provide price
                  history for Flipkart and free price tracker for Flipkart.With
                  Flipkart's dynamic pricing you can buy products cheaper if you
                  track products with Histro Price. Track previous price and set
                  price alerts to get best deals for your favourite products on
                  Flipkart.
                </p>
                <h4>Histro Price For Myntra</h4>
                <p>
                  Myntra is the biggest hub for fashionable products. With our
                  free tools, you can check the Histro Price for Myntra in few
                  taps. Alongside, we provide the best price tracker for Myntra
                  where you can set price alerts and can save a lot on Myntra
                  products.
                </p>
                <h4>Histro Price for more stores</h4>
                <p>
                  In total, we have 9 Indian stores for which we provide price
                  history and price tracking features. Other stores for which
                  you can check Histro Price and price tracker are Nykaa,
                  NykaaMan, NykaaFashion, Ajio, TataCliq, and Croma.
                </p>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </HelmetProvider>
  );
}
