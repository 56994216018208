import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import call from "../images/contact.png";
import { Helmet, HelmetProvider } from "react-helmet-async";
import axios from "axios";
import api from "./api";

export default function Contact() {
  const [metaData, setMetaData] = useState(null);
  const pageName = "contact";
  useEffect(() => {
    const fetchMetaTags = async () => {
      try {
        const response = await api.post("", {
          method_name: "meta_keyword",
          page_name: pageName,
        });

        const pageMeta = response.data.histro_Price[0];

        if (pageMeta) {
          setMetaData(pageMeta);
        } else {
          console.error("No meta data found for this page.");
        }
      } catch (error) {
        console.error("Error fetching meta data:", error);
      }
    };

    fetchMetaTags();
  }, [pageName]);

  const renderMetaTags = () => {
    if (!metaData) return null;

    return (
      <>
        {metaData.description && (
          <meta name="description" content={metaData.description} />
        )}
        {metaData.keywords && (
          <meta name="keywords" content={metaData.keywords} />
        )}
        {metaData.image && (
          <meta name="robots" content="max-image-preview:large" />
        )}
        {metaData.url && <link rel="canonical" href={metaData.url} />}
        {metaData.type && <meta property="og:type" content={metaData.type} />}
        {metaData.title && (
          <meta property="og:title" content={metaData.title} />
        )}
        {metaData.description && (
          <meta property="og:description" content={metaData.description} />
        )}
        {metaData.url && <meta property="og:url" content={metaData.url} />}
        {metaData.image && (
          <meta property="og:image" content={metaData.image} />
        )}
        {metaData.image && (
          <meta property="og:image:secure_url" content={metaData.image} />
        )}
        {metaData.image && (
          <meta name="twitter:card" content="summary_large_image" />
        )}
        {metaData.title && (
          <meta name="twitter:title" content={metaData.title} />
        )}
        {metaData.description && (
          <meta name="twitter:description" content={metaData.description} />
        )}
        {metaData.image && (
          <meta name="twitter:image" content={metaData.image} />
        )}
      </>
    );
  };

  return (
    <div>
      <HelmetProvider>
        <div>
          <Helmet>
            {renderMetaTags()}
            <title>{metaData ? metaData.title : "Contact Us"}</title>
          </Helmet>
          <section className="py-0 my-0">
            <div className="container-fluid">
              <div className="bg-warning py-3 rounded-5">
                <div className="container">
                  <div className="row">
                    <div className="three mb-4">
                        <title>{metaData?.title || "Contact Us"}</title>
                    </div>
                    <div className="col-md-4">
                      <img
                        src={call}
                        alt="phone"
                        className="image-float img-fluid"
                      />
                    </div>
                    <div className="col-md-8">
                      <div className="d-flex gap-2 flex-wrap">
                        <div className="contact-info-content">
                          <h4>Email</h4>
                          <p>info@wavecloth.com</p>
                        </div>
                        <p>
                          We value your feedback, questions, and concerns.
                          Whether you need assistance, have a question about our
                          products or services, or simply want to share your
                          thoughts, we're here to help!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <br />
        <Footer />
      </HelmetProvider>
    </div>
  );
}
