import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useParams, Link } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import axios from "axios";
import { BASE_URL } from "./config";

export default function Product() {
  const { slug } = useParams();
  const [product, setProduct] = useState(null);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [metaData, setMetaData] = useState(null);
  const pageName = "Product";
  const slugName = slug;

  const fetchProductData = async () => {
    try {
      const response = await fetch(BASE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          method_name: "single_product",
          slug: slug,
        }),
      });

      const result = await response.json();

      if (result && result.histro_Price && result.histro_Price.length > 0) {
        const foundProduct = result.histro_Price[0];
        setProduct(foundProduct);

        fetchSimilarProducts(foundProduct.category, foundProduct.id);
      } else {
        setError("Product not found.");
      }
    } catch (error) {
      setError("An error occurred while fetching the product data.");
    } finally {
      setLoading(false);
    }
  };

  const fetchSimilarProducts = async (category, id) => {
    try {
      const response = await fetch(BASE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          method_name: "category_by_product",
          category: category,
          id: id,
        }),
      });

      const result = await response.json();

      if (result && result.histro_Price) {
        setSimilarProducts(result.histro_Price.slice(0, 4));
      } else {
        setSimilarProducts([]);
      }
    } catch (error) {
      setError("An error occurred while fetching similar products.");
    }
  };

  useEffect(() => {
    setLoading(true);
    setError(null);
    fetchProductData();
    const fetchMetaTags = async () => {
      try {
        const response = await axios.post(BASE_URL, {
          method_name: "meta_keyword",
          page_name: pageName,
          slug_name: slugName,
        });

        const pageMeta = response.data.histro_Price[0];

        if (pageMeta) {
          setMetaData(pageMeta);
        } else {
          console.error("No meta data found for this page.");
        }
      } catch (error) {
        console.error("Error fetching meta data:", error);
      }
    };
    fetchMetaTags();
  }, [slug, pageName]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!product) {
    return <div>Product not found.</div>;
  }

  const calculateDiscountPercentage = (originalPrice, salePrice) => {
    if (!originalPrice || !salePrice) return 0;
    const original = parseFloat(originalPrice.replace(/,/g, ""));
    const sale = parseFloat(salePrice.replace(/,/g, ""));
    return ((original - sale) / original) * 100;
  };

  const discountPercentage = calculateDiscountPercentage(
    product.price,
    product.sell_price
  );

  const getTimerClass = (category, productTime) => {
    const lightClasses = {
      exactly: "bg-light-green",
      indeed: "bg-light-yellow",
      maybe: "bg-light-orange",
      nope: "bg-light-red",
    };

    const darkClasses = {
      exactly: "bg-dark-green",
      indeed: "bg-dark-yellow",
      maybe: "bg-dark-orange",
      nope: "bg-dark-red",
    };

    let className = lightClasses[category];

    if (productTime === category) {
      className = darkClasses[category];
    }

    return className;
  };
  const renderMetaTags = () => {
    if (!metaData) return null;

    return (
      <>
        {metaData.description && (
          <meta name="description" content={metaData.description} />
        )}
        {metaData.keywords && (
          <meta name="keywords" content={metaData.keywords} />
        )}
        {metaData.image && (
          <meta name="robots" content="max-image-preview:large" />
        )}
        {metaData.url && <link rel="canonical" href={metaData.url} />}
        {metaData.type && <meta property="og:type" content={metaData.type} />}
        {metaData.title && (
          <meta property="og:title" content={metaData.title} />
        )}
        {metaData.description && (
          <meta property="og:description" content={metaData.description} />
        )}
        {metaData.url && <meta property="og:url" content={metaData.url} />}
        {metaData.image && (
          <meta property="og:image" content={metaData.image} />
        )}
        {metaData.image && (
          <meta property="og:image:secure_url" content={metaData.image} />
        )}
        {metaData.image && (
          <meta name="twitter:card" content="summary_large_image" />
        )}
        {metaData.title && (
          <meta name="twitter:title" content={metaData.title} />
        )}
        {metaData.description && (
          <meta name="twitter:description" content={metaData.description} />
        )}
        {metaData.image && (
          <meta name="twitter:image" content={metaData.image} />
        )}
      </>
    );
  };

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>{metaData?.title || "Single Product - Histro Price"}</title>
          {renderMetaTags()}
        </Helmet>
        <div className="container mt-3 mb-3">
          <div className="row d-flex justify-content-center">
            <div className="col-md-10">
              <div className="card">
                <div className="row">
                  <div className="col-md-6">
                    <div className="images p-3">
                      <div className="text-center p-4">
                        <img
                          id="main-image"
                          src={product.image}
                          className="images_cust_width"
                          alt="Main"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="product p-4">
                      <div className="mt-4 mb-3">
                        <h1 className="text-uppercase">{product.name}</h1>
                        <div className="price d-flex flex-row align-items-center">
                          <span className="act-price">
                            ₹{product.sell_price}
                          </span>
                          {product.price !== product.sell_price && (
                            <div className="ml-2">
                              <small className="dis-price">
                                ₹{product.price}
                              </small>
                              <span className="discount">
                                {discountPercentage.toFixed(2)}% OFF
                              </span>
                            </div>
                          )}
                        </div>
                        <p className="cust_p">Price updated </p>
                      </div>
                      <div className="box_border">
                        <p className="font-medium md:text-center text-center text-sm">
                          Is this a good time to buy this product?
                        </p>
                        <div className="progress">
                          <div
                            className={`progress-bar ${getTimerClass(
                              "exactly",
                              product.product_time
                            )}`}
                            style={{ width: "25%" }}
                          >
                            Exactly
                          </div>
                          <div
                            className={`progress-bar ${getTimerClass(
                              "indeed",
                              product.product_time
                            )}`}
                            style={{ width: "25%" }}
                          >
                            Indeed
                          </div>
                          <div
                            className={`progress-bar ${getTimerClass(
                              "maybe",
                              product.product_time
                            )}`}
                            style={{ width: "25%" }}
                          >
                            Maybe
                          </div>
                          <div
                            className={`progress-bar ${getTimerClass(
                              "nope",
                              product.product_time
                            )}`}
                            style={{ width: "25%" }}
                          >
                            Nope
                          </div>
                        </div>

                        <p className="text-gray-500 dark:text-gray-400 cust_p text-sm">
                          This is absolutely the best time to buy this product.
                          Don't miss out, Drop chances are lower than{" "}
                          {discountPercentage.toFixed(2)}%
                        </p>
                      </div>
                      <div className="d-flex gap-3 pt-3 center_property">
                        <a
                          href={product.product_link}
                          target="_blank"
                          rel="noopener noreferrer"
                          title={`Buy ${product.name} on Amazon`}
                        >
                          <button type="button" className="btn btn-primary">
                            Buy on {" "}
                            <span className="text-xs font-semibold text-gray-500 capitalize_text">
                              {" "}
                              {product.store_name}
                            </span>
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="product-description">
                    <p className="description">
                      {product.description.split("\n").map((line, index) => {
                        const [key, value] = line.split(":");
                        if (key && value) {
                          return (
                            <div key={index}>
                              <strong >{key.trim()}:</strong> {value.trim()}
                            </div>
                          );
                        }
                        return <div key={index}>{line}</div>;
                      })}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="py-3">
          <div className="container-fluid">
            <div className="row">
              <div className="tab-content" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="nav-all"
                  role="tabpanel"
                  aria-labelledby="nav-all-tab"
                >
                  <div className="product-grid row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
                    <div className="three">
                      <h2 className="top_design">Similar Products</h2>
                    </div>
                    {similarProducts.map((product) => (
                      <div className="col-md-3" key={product.id}>
                        <div className="product-item position-relative">
                          {product.sell_price && (
                            <span className="badge bg-success position-absolute m-3">
                              -{" "}
                              {Math.round(
                                ((parseFloat(product.price.replace(/,/g, "")) -
                                  parseFloat(
                                    product.sell_price.replace(/,/g, "")
                                  )) /
                                  parseFloat(product.price.replace(/,/g, ""))) *
                                  100
                              )}
                              %
                            </span>
                          )}
                          <figure>
                            <Link
                              to={`/product/${product.slug}`}
                              title={product.name}
                            >
                              <img
                                src={product.image}
                                className="tab-image"
                                alt={product.name}
                              />
                            </Link>
                          </figure>
                          <h3 className="product_name">{product.name}</h3>
                          <span className="rating">
                            {[...Array(Math.floor(product.rate))].map(
                              (_, index) => (
                                <i
                                  key={index}
                                  className="fa fa-star text-primary"
                                  aria-hidden="true"
                                ></i>
                              )
                            )}
                            {product.rate % 1 !== 0 && (
                              <i
                                className="fa fa-star-half-alt text-primary"
                                aria-hidden="true"
                              ></i>
                            )}
                            {[...Array(5 - Math.ceil(product.rate))].map(
                              (_, index) => (
                                <i
                                  key={index + Math.floor(product.rate)}
                                  className="fa fa-star-o text-primary"
                                  aria-hidden="true"
                                ></i>
                              )
                            )}
                          </span>
                          <span className="price">
                            ₹{product.sell_price || product.price}
                          </span>
                          <div className="d-flex align-items-center justify-content-between">
                            <span className="text-xs font-semibold uppercase text-gray-500">
                              {product.store_name}
                            </span>
                            <time
                              dateTime={product.updated_at}
                              title={product.updated_at}
                            >
                              {product.updated_at}
                            </time>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </HelmetProvider>
  );
}
